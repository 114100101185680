import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const SERVICE_LOCALES = {
	WOLTAIR_CZ: 'servis',
	WOLTAIR_PL: 'serwis'
}

export const SERVICE = SERVICE_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return SERVICE === param
}) satisfies ParamMatcher
